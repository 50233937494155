.fade-in {
  opacity: 0.2;
  transition: opacity 0.6s cubic-bezier(0, 0, 0.58, 1);
}
.fade-in.in-view {
  opacity: 1;
}

.fade-in-up {
  opacity: 0.2;
  transform: translateY(20px);
  transition:
    opacity 0.7s cubic-bezier(0, 0, 0.58, 1),
    transform 0.7s cubic-bezier(0, 0, 0.58, 1);
}
.fade-in-up.in-view {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-scale {
  opacity: 0.2;
  transform: scale(0.6);
  transition:
    opacity 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.fade-in-scale.in-view {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

.block-reveal {
  position: relative;
  display: inline-block;
  &:after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 0%;
    height: 100%;
    background: var(--color-primary);
  }
  span {
    opacity: 0;
  }
  &.in-view {
    span {
      animation: appear-text 0.0001s 0.5s linear forwards;
    }
    &:after {
      animation: rev-block 1s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    }
  }

  &-title {
    position: relative;
    margin-bottom: rem(18);
    color: var(--color-primary);
    font-size: 20px;
    font-weight: var(--font-medium);
    letter-spacing: 0.1px;
    z-index: 2;
    text-align: center;

    @include breakpoint(medium) {
      // display: block;
      // width: 78%;
      max-width: 940px;
      margin: auto;
      margin-bottom: rem(24);
    }

    @include breakpoint(large) {
      font-size: rem(35);
    }

    &-wrap {
      text-align: center;
    }

    strong,
    b {
      font-weight: var(--font-demi);
    }
  }
}

@keyframes rev-block {
  0% {
    left: 0;
    width: 0%;
  }
  50% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}

@keyframes appear-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader1 {
  @keyframes spin {
    0% {
      transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -3px) rotate(36deg);
    }
    20% {
      transform: translate(-2px, 0px) rotate(72deg);
    }
    30% {
      transform: translate(1px, 2px) rotate(108deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(144deg);
    }
    50% {
      transform: translate(-1px, 3px) rotate(180deg);
    }
    60% {
      transform: translate(-1px, 1px) rotate(216deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(252deg);
    }
    80% {
      transform: translate(-2px, -1px) rotate(288deg);
    }
    90% {
      transform: translate(2px, 1px) rotate(324deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(360deg);
    }
  }
  @keyframes speed {
    0% {
      transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -3px) rotate(-1deg);
    }
    20% {
      transform: translate(-2px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 3px) rotate(-1deg);
    }
    60% {
      transform: translate(-1px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-2px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(2px, 1px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
  @keyframes strikes {
    from {
      left: 25px;
    }
    to {
      left: -80px;
      opacity: 0;
    }
  }
  @keyframes dots {
    from {
      width: 0px;
    }
    to {
      width: 15px;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  background: rgba(0, 0, 0, 0.8);
  color: var(--color-primary);
  font-weight: var(--font-demi);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  gap: 16px;
  animation: fadeIn 0.4s both;

  .fadeIn {
    animation: fadeIn 0.4s both;
  }

  .text {
    position: relative;
    width: auto;
  }
  .dots {
    display: inline-block;
    width: 5px;
    overflow: hidden;
    vertical-align: bottom;
    animation: dots 1.5s linear infinite;
    transition: 1;
  }

  .car {
    position: relative;
    width: 117px;
    height: 42px;
  }
  .car .strike {
    position: absolute;
    width: 11px;
    height: 1px;
    background: var(--color-primary);
    animation: strikes 0.2s linear infinite;
  }
  .car .strike2 {
    top: 11px;
    animation-delay: 0.05s;
  }
  .car .strike3 {
    top: 22px;
    animation-delay: 0.1s;
  }
  .car .strike4 {
    top: 33px;
    animation-delay: 0.15s;
  }
  .car .strike5 {
    top: 44px;
    animation-delay: 0.2s;
  }
  .car-detail {
    position: absolute;
    display: block;
    background: var(--color-primary);
    animation: speed 0.5s linear infinite;
  }
  .car-detail.spoiler {
    width: 0;
    height: 0;
    top: 7px;
    background: none;
    border: 20px solid transparent;
    border-bottom: 8px solid var(--color-primary);
    border-left: 20px solid var(--color-primary);
  }
  .car-detail.back {
    height: 20px;
    width: 92px;
    top: 15px;
    left: 0px;
  }
  .car-detail.center {
    height: 35px;
    width: 75px;
    left: 12px;
    border-top-left-radius: 30px;
    border-top-right-radius: 45px 40px;
    border: 4px solid var(--color-primary);
    background: none;
    box-sizing: border-box;
  }
  .car-detail.center1 {
    height: 35px;
    width: 35px;
    left: 12px;
    border-top-left-radius: 30px;
  }
  .car-detail.front {
    height: 20px;
    width: 50px;
    top: 15px;
    left: 67px;
    border-top-right-radius: 50px 40px;
    border-bottom-right-radius: 10px;
  }
  .car-detail.wheel {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    top: 20px;
    left: 12px;
    border: 3px solid #333;
    background: linear-gradient(
        45deg,
        transparent 45%,
        var(--color-primary) 46%,
        var(--color-primary) 54%,
        transparent 55%
      ),
      linear-gradient(
        -45deg,
        transparent 45%,
        var(--color-primary) 46%,
        var(--color-primary) 54%,
        transparent 55%
      ),
      linear-gradient(
        90deg,
        transparent 45%,
        var(--color-primary) 46%,
        var(--color-primary) 54%,
        transparent 55%
      ),
      linear-gradient(
        0deg,
        transparent 45%,
        var(--color-primary) 46%,
        var(--color-primary) 54%,
        transparent 55%
      ),
      radial-gradient(
        var(--color-primary) 29%,
        transparent 30%,
        transparent 50%,
        var(--color-primary) 51%
      ),
      #333;
    animation-name: spin;
  }
  .car-detail.wheel2 {
    left: 82px;
  }
}

.loader2 {
  .preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    background: rgba(0, 0, 0, 0.6);
  }
  .loader {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--color-primary);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: var(--color-secondary);
      -webkit-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
    }

    &:after {
      content: '';
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: var(--color-gray3);
      -webkit-animation: spin 1.5s linear infinite;
      animation: spin 1.5s linear infinite;
    }
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.loader3 {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  font-weight: var(--font-demi);

  @keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      transform: translate3d(0, -30px, 0);
    }

    70% {
      transform: translate3d(0, -15px, 0);
    }

    90% {
      transform: translate3d(0, -4px, 0);
    }
  }

  .loader {
    width: 140px;
    height: 140px;
    text-align: center;
    color: var(--color-primary);
  }

  .bounce {
    margin-top: 30px;
    animation: bounce 1.5s infinite;
  }

  .wheel {
    fill: var(--color-primary);
    animation: spin 2s linear infinite;
  }
}

.loaderAbsolute {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  color: var(--color-white);
  font-size: 17px;
  background: rgba(0, 0, 0, 0.8);

  // &_inner {
  //   background: #000;
  // }

  img {
    max-width: 120px;
  }

  .text {
    padding: 6px 0 6px 10px;
  }
}
